import React from "react"
import Layout from "../components/Layouts/layout"
import Button from "../components/Button/Button"
import background from "../images/page-404.jpg"
import "../styles/page-404.scss"
const NotFoundPage = () => {
  return (
    <Layout>
      <section className="page-404">
        <div className="container">
          <div className="page-content">
            <p>Uh Oh, Looks Like You Have The Wrong Address...</p>
            <span>Let Us Help You</span>
            <Button
              button={{
                text: "MOVE BACK TO HOME PAGE",
                url: "/",
                type: "transparent",
              }}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
